import Cookies from 'js-cookie';
import apiFetch from '@wordpress/api-fetch';


export default {
    init() {
        /**
         * Manual AAM redirect overrides
         * This code redirects users if they have two cookies set. They are deleted eventually.
         * Extremely roundabout and should be superseded with functionality that overrides AAM.
         */
            //Check if we're getting a referer that matches what's coming from the email
        let emailQueryString = window.location.search;
        let emailUrlParams = new URLSearchParams(emailQueryString);
        const emailRedirectedUser = Cookies.get('is_redirected');
        let inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);

        if(emailUrlParams.has('email_ref')) {
            Cookies.set('is_redirected', 0, {expires: inFiveMinutes});
        }

        if (emailRedirectedUser == 0 && document.body.classList.contains('logged-in')) {
            //Redirect user to exclusive content
            window.location.replace("/bonus-content/#articles");
        }

        if(emailRedirectedUser == 0 &&
            document.body.classList.contains('bonus-content') &&
            document.body.classList.contains('logged-in')){
            Cookies.set('is_redirected', 1, {expires: inFiveMinutes});
            Cookies.remove('is_redirected');
        }
    },
    finalize() {
        window.addEventListener("resize", (e) => {
            setTimeout(() => {
                rearrangeSidebar();
            }, 200);
        });

        // media query for mobile
        let laptopLg = window.matchMedia("(min-width: 1050px)");

        const rearrangeSidebar = () => {
            const sidebarTemplateContent = document.querySelector(
                ".sidebar-template-content"
            );
        if (sidebarTemplateContent) {
            const sidebar = document.querySelector(".sidebar");
            const mainContent = document.querySelector(".main-content");

            if (laptopLg.matches) {
                // parent element.insertBefore(element you want second, element you want first)
                sidebarTemplateContent.insertBefore(sidebar, mainContent);
            } else {
                // parent element.insertBefore(element you want second, element you want first)
                sidebarTemplateContent.insertBefore(mainContent, sidebar);
            }
        }
        };
        rearrangeSidebar();

        const isSafari = () => {
            // Safari 3.0+ "[object HTMLElementConstructor]"
            const browserIsSafari =
                /constructor/i.test(window.HTMLElement) ||
                (function (p) {
                    return p.toString() === "[object SafariRemoteNotification]";
                })(
                    !window["safari"] ||
                        (typeof safari !== "undefined" &&
                            safari.pushNotification)
                );
        if (browserIsSafari == true) {
            $("html").addClass("safari");
        }
        };
        isSafari();
        // JavaScript to be fired on all pages, after page specific JS is fired
        /* SKIP LINKS */
        // skip link scroll to section
        const skipToAnchor = (aid) => {
            var aTag = $(aid);
            var focus = true;
            $("html,body").animate(
                {
                    scrollTop: aTag.offset().top,
                },
                "slow"
            );
            var first_child = $(aTag.children()[0]);
            var tag = first_child.prop("tagName").toLowerCase();

        if (
                tag !== "a" &&
                tag !== "button" &&
                tag !== "input" &&
                tag !== "textarea"
            ) {
            if (first_child.attr("tabIndex") !== undefined) {
                first_child.attr("tabIndex", -1).focus();
                first_child.removeAttr("tabIndex");
            } else {
                first_child.focus();
            }
        } else {
            first_child.focus();
        }
        };

        // create skip links
        const skipLinks = () => {
            $("section").each(function () {
                const id = $(this).attr("id");
                if (id !== undefined) {
                    // Use the section id to create a label for the items in the skip link list
                    var sectionNameArray = id.split("-");
                    var sectionName = "";
                    for (var i = 0; i < sectionNameArray.length; i++) {
                        var str = sectionNameArray[i];
                        str = str
                            .toLowerCase()
                            .replace(/\b[a-z]/g, function (letter) {
                                return letter.toUpperCase();
                            });
                        sectionName += str;
                        if (i < sectionNameArray.length - 1) {
                            sectionName += " ";
                        }
                    }
                    var skiplink =
                        "<li><a href='#" +
                        id +
                        "' class='text-link'>Skip to " +
                        sectionName +
                        "</a></li>";
                    $(".skiplinks ul").append(skiplink);
                }
            });

            const skipLinkContainer = $(".skiplinks"),
                skipLink = $(".skiplinks a");

            skipLink.on("focus", function () {
                skipLinkContainer.addClass("show");
            });

            skipLink.on("blur", function () {
                skipLinkContainer.removeClass("show");
            });

            skipLink.on("click", function (e) {
                e.preventDefault();
                skipToAnchor($(this).attr("href"));
            });
        };
        skipLinks();

        const accordionToggle = () => {
            const accordion = document.querySelector(".accordion");
            // check if accordion exists

            if (accordion) {
                // determine if nested or simple accordion
                if (accordion.classList.contains("nested")) {
                    const headerButtons = accordion.querySelectorAll(
                        ".module-header button.title, .module-header button.date, .module-header button.expand"
                    );
                    const innerFolderButtons = accordion.querySelectorAll(
                        ".folder-content-header button.title, .folder-content-header button.date, .folder-content-header button.expand"
                    );
                    const innerFolders = accordion.querySelectorAll(
                        ".module-content-folder"
                    );

                    headerButtons.forEach((button) => {
                        button.addEventListener("click", (e) => {
                            e.preventDefault();
                            const currentModule =
                                e.currentTarget.closest(".module");
                            const currentExpand =
                                currentModule.querySelector(".expand .btn-txt");
                            if (currentModule.classList.contains("open")) {
                                currentModule.classList.remove("open");
                                currentExpand.innerText = "Expand";
                            } else {
                                currentModule.classList.add("open");
                                currentExpand.innerText = "Collapse";
                            }
                            // close inner folders if open
                            innerFolders.forEach((folder) => {
                                const innerExpand =
                                    folder.querySelector(".expand .btn-txt");
                                folder.classList.remove("open");
                                innerExpand.innerText = "Expand";
                            });
                        });
                    });

                    innerFolderButtons.forEach((innerButton) => {
                        innerButton.addEventListener("click", (e) => {
                            e.preventDefault();
                            const currentInnerFolder = e.currentTarget.closest(
                                ".module-content-folder"
                            );
                            const currentInnerExpand =
                                currentInnerFolder.querySelector(
                                    ".expand .btn-txt"
                                );

                        if (currentInnerFolder.classList.contains("open")) {
                            currentInnerFolder.classList.remove("open");
                            currentInnerExpand.innerText = "Expand";
                        } else {
                            currentInnerFolder.classList.add("open");
                            currentInnerExpand.innerText = "Collapse";
                        }
                        });
                    });
                } else if (accordion.classList.contains("simple")) {
                    const buttons = accordion.querySelectorAll(
                        ".accordion-section-header"
                    );
                    buttons.forEach((button) => {
                        button.addEventListener("click", (e) => {
                            e.preventDefault();
                            const currentSection =
                                e.currentTarget.closest(".accordion-section");
                            const currentExpand =
                                currentSection.querySelector(
                                    ".expand .btn-txt"
                                );
                            console.log(currentExpand);
                        if (currentSection.classList.contains("open")) {
                            currentSection.classList.remove("open");
                            currentExpand.innerText = "Expand";
                        } else {
                            currentSection.classList.add("open");
                            currentExpand.innerText = "Collapse";
                        }
                        });
                    });
                }
            }
        };
        accordionToggle();

        const openProfileNotification = () => {
            const isLoginPage = document.body.classList.contains('home');
            const profileCookie = Cookies.get('profileModalSeen');
            if (!profileCookie && !isLoginPage) {
                const hiddenButton = document.querySelector('.profile-notification-trigger');
                if (hiddenButton) {
                    hiddenButton.click();
                    Cookies.set('profileModalSeen', 'true', {expires: 30});
                }
            }
        }



        // privacy popup



        const privacyPopup = document.getElementById('privacy-popup');
        const checkPrivaycPolicy = async() => {

            const userData = await apiFetch({
                path: '/wp-json/wp/v2/users/me',
                headers: { 'X-WP-Nonce': wpApiSettings.nonce }
            });

        if (!userData.acf.accepted_privacy_policy && privacyPopup) {
            privacyPopup.classList.add('active');
            const privacyButton = privacyPopup.querySelector('button.accept');

            // When the 'accept' button is clicked:
            privacyButton.addEventListener('click', async(e) => {

                e.preventDefault();

                // Let's use the API to set accepted_privacy_policy to true:
                const postAccepted = await apiFetch({
                    method: 'POST',
                    path: '/wp-json/wp/v2/users/me',
                    headers: { 'X-WP-Nonce': wpApiSettings.nonce },
                    data: {
                        "acf": {
                            "accepted_privacy_policy": true
                        }
                    },
                    }).catch((error) => {
                        console.error(error);
                    })

                // And now let's set the cookie so we don't have to deal with this on another pageload:
                Cookies.set('acceptedPrivacyPopup', 'true', {expires: 1});

                // And let's hide the popup banner
                privacyPopup.classList.remove('active');
                privacyPopup.classList.contains('active')
                    ? privacyPopup.setAttribute('aria-hidden', 'false')
                    : privacyPopup.setAttribute('aria-hidden', 'true')

            });
            // Triggers profile edit Modal on first visit. and if Privacy policy is not yet accepted
            setTimeout(() => {
                if (document.body.classList.contains('logged-in')) {
                    openProfileNotification();
                }
                }, 1000);

        } else if (userData.acf.accepted_privacy_policy) {

            // Otherwise, we know they accepted it, there's just no cookie set
            // So let's set one to avoid this loop:
            Cookies.set('acceptedPrivacyPopup', 'true', {expires: 1});
        }

        }

        // Check to see if user has the privacy popup cookie set, and if not, let's check it via API calls:
        const acceptedPrivacyPopup = Cookies.get('acceptedPrivacyPopup');
        if (!acceptedPrivacyPopup && document.body.classList.contains('logged-in')) {
            checkPrivaycPolicy();
        }

        const phoneNumbersToFormat = document.querySelectorAll('.format-on-blur input');
        if (phoneNumbersToFormat.length) {
            phoneNumbersToFormat.forEach(input => {
                input.addEventListener('blur', function (e) {
                    var x = e.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
                    e.target.value = '(' + x[1] + ') ' + x[2] + '-' + x[3];
                });
            })
        };

        // reveal password
        const pwButton1 = document.getElementById("pwBtn1");
        const pwButton2 = document.getElementById("pwBtn2");
        const pwField1 = document.getElementById("pass1");
        const pwField2 = document.getElementById("pass2");

        function togglePassword1()
        {
            const type = pwField1.getAttribute("type") === "password" ? "text" : "password";
            pwField1.setAttribute("type", type);
            pwButton1.classList.toggle("show");
        }

        function togglePassword2()
        {
            const type = pwField2.getAttribute("type") === "password" ? "text" : "password";
            pwField2.setAttribute("type", type);
            pwButton2.classList.toggle("show");
        }

        if (pwButton1 || pwButton2) {
            pwButton1.addEventListener("click", togglePassword1);
            pwButton2.addEventListener("click", togglePassword2);
        }


        // login page PW reveal
        const pwField = document.getElementById("aam-login-password-widget-aam_backend_login-3-loginform");
        const pwBtn = "<button type='button' id='login_pwBtn' class='toggle-pw' aria-live='polite' aria-label='Show Password' data-label-show='Show Password' data-label-hide='Hide Password'><span class='dashicons dashicons-hidden' aria-hidden='true'></span></button>";
        if (pwField) {
            pwField.parentElement.insertAdjacentHTML("beforeend", pwBtn);
        }

        const login_pwButton = document.getElementById("login_pwBtn");

        function login_togglePassword()
        {
            const login_type = pwField.getAttribute("type") === "password" ? "text" : "password";
            pwField.setAttribute("type", login_type);
            login_pwButton.classList.toggle("show");
        }

        if (login_pwButton) {
            login_pwButton.addEventListener("click", login_togglePassword);
        }

    },
};
